import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Icons } from '@atline-shared/enums/icons.enum';
import { PaToken } from '@atline/core/models/api/responses/authenticate.response';
import { UtilsService } from '@atline/core/services/utils.service';

@Component({
  selector: 'app-pa-select',
  templateUrl: './pa-select.component.html',
  styleUrls: ['./pa-select.component.scss']
})
export class PaSelectComponent implements OnInit {

  clePa!: string;
  pas!: PaToken[];

  iconCheck = Icons.Check;

  constructor(
    readonly dialogRef: MatDialogRef<PaSelectComponent>,
    readonly utilServices: UtilsService
  ) {
    this.pas = utilServices.getPaList();
  }

  ngOnInit(): void {

  }
}
