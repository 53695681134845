import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../../../core/material/material.module';
import {LoaderComponent} from './loader.component';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { DialogModule } from '@atline-shared/design/dialog/dialog.module';



@NgModule({
  declarations: [LoaderComponent],
  exports: [
    LoaderComponent
  ],
    imports: [
        CommonModule,
        MaterialModule,
        NgxTranslateModule,
        DialogModule
    ]
})
export class LoaderModule { }
