<!-- <div class="loader" fxLayout="row" fxLayoutAlign="center center">
  <div mat-dialog-content >
    <mat-spinner [style.margin]="'auto'" [diameter]="50"></mat-spinner>
  </div>
  <h5 class="loader-title">{{ title | translate }}</h5>
</div> -->

<app-dialog [dialogRef]="dialogRef" [cross]="false">

  <div header>
    {{ title | translate }}
  </div>

  <div body fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner [style.margin]="'auto'" [diameter]="50"></mat-spinner>

  </div>

</app-dialog>