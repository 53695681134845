<div class="container" fxLayout="column">

  <app-page-title [key]="'consultation'" [nbAvalaible]="nbConsultation">
    <app-favoris-star customRightPart *ngIf="isAuthenticate" [fxHide.xs]="true"></app-favoris-star>
  </app-page-title>


  <app-search-form class="form-container" (submit)="submit()">

    <form #searchTemplate [formGroup]="searchConsultation" class="form-search-cons" (submit)="submit()">

      <section fxLayout="row wrap" class="prestations" fxLayoutAlign="start center">
        <ng-container *ngFor="let item of prestationList">
          <app-round-checkbox [code]="item.code" [name]="item.name" [title]="'APP.' + item.key | translate"
            (changeState)="updatePrestationState(item.name)">
            <label [for]="item.name" class="no-select-hightlight" style="cursor: pointer;"> {{('APP.' + item.key) |
              translate}} </label>
          </app-round-checkbox>
        </ng-container>
      </section>


      <section class="inputs-search" fxLayoutAlign="start center" fxLayout.lt-lg="column">
        <div fxLayoutAlign="center center" class="input-element-bg-gray">
          <span class="input-prefix-container">
            <mat-icon svgIcon="target" id="icon-target"
              [title]="'APP.MARCHES_SECURISES.CONSULTATION.SEARCH.ALT.KEYWORD' | translate"></mat-icon>
          </span>
          <mat-divider [vertical]="true"></mat-divider>
          <input formControlName="motscles" type="text"
            [placeholder]="'APP.MARCHES_SECURISES.CONSULTATION.PLACEHOLDER.SUBJECT' | translate" class="targetInput" />
        </div>

        <div fxLayoutAlign="center center" class="input-element-bg-gray">
          <span class="input-prefix-container">
            <mat-icon svgIcon="pin" id="icon-pin"
              [title]="'APP.MARCHES_SECURISES.CONSULTATION.SEARCH.ALT.LOC' | translate"></mat-icon>
          </span>
          <mat-divider [vertical]="true"></mat-divider>
          <input formControlName="departement" type="text"
            [placeholder]="'APP.MARCHES_SECURISES.CONSULTATION.PLACEHOLDER.LOCALISATION' | translate"
            class="targetInput" />
        </div>
      </section>
      <button style="opacity: 0;" type="submit"></button>

      <!-- <hr>

      <section class="section-dates">
        <h5 id="date-title">{{'APP.MARCHES_SECURISES.CONSULTATION.SEARCH.DATES' | translate}}</h5>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="dates">
          <div class="input-element-bg-gray" fxLayout="row" fxLayoutAlign="start center">
            <input [ngModel]="dateStart" matInput [matDatepicker]="pickerStart" formControlName="control"
              [max]="dateEnd" placeholder="JJ/MM/YYYY"/>
            <mat-datepicker-toggle class="toggle-datetime-picker" matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
          </div>
          <div class="input-element-bg-gray" fxLayout="row" fxLayoutAlign="start center">
            <input [ngModel]="dateEnd" matInput [matDatepicker]="pickerStart" [max]="dateEnd" placeholder="JJ /MM/YYYY"/>
            <mat-datepicker-toggle class="toggle-datetime-picker" matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
          </div>
        </div>

      </section> -->

    </form>

  </app-search-form>

</div>